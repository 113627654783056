import React, { useState, useEffect } from "react";
import { ApiServiceClass } from "../components/api-service.js";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    MenuItem,
    Select,
    TextField,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import iphoneImage from '../components/myapp/resources/iphone2.png';
import NotificationReviewPopup from './push-notification-review'; // Import the Review Popup

export default function PushNotificationForm({
    addOpen,
    handleAddClose,
    setTitle,
    setMessage,
    setGroupId,
    setScheduledDate,
    setScheduledTime,
    setEndDate,
    setEndTime,
    setIsScheduledNow,
    setIsSeries,
    setFrequencyId,
    initialTitle,
    initialMessage,
    initialGroupId,
    companyId,
    loginUserId,
    marketingCredits,
    fetchData,
}) {
    const [targetGroupText, setTargetGroupText] = useState('');
    const [notificationData, setNotificationData] = useState({
        // title: initialTitle || '',
        message: initialMessage || '',
        groupId: initialGroupId || 1,
        frequencyId: 1,
        scheduledDate: "",
        scheduledTime: "",
        endDate: "",
        endTime: "",
        isScheduledNow: true,
        isSeries: false,
    });
    const [reviewOpen, setReviewOpen] = useState(false);
    const [pushCostData, setPushCostData] = useState({
        numUsers: 'n/a',
        numCredits: 'n/a',
    });
    const [messageWarningShown, setMessageWarningShown] = useState(false);

    useEffect(() => {
        setTargetGroupText(getGroupLabel(notificationData.groupId));
    }, [notificationData.groupId]);

    const getGroupLabel = (groupId) => {
        switch (groupId) {
            case 1:
                return 'All users';
            case 2:
                return 'Users active in the last 7 days';
            case 3:
                return 'Users last active over 1 month ago';
            case 4:
                return 'Users that have claimed a reward in the last month';
            default:
                return 'Unknown group';
        }
    };

    const schedulePushNotification = async (pushNotification) => {
        try {
            const scheduledDateTime = "";
            if (scheduledDate && scheduledTime) {
                scheduledDateTime = new Date(`${scheduledDate}T${scheduledTime}`);
            }
            var requestBody = {
                CompanyId: companyId,
                // RequesterUserId: loginUserId,
                Message: message,
                CustomerGroup: groupId,
                ScheduledTime: scheduledDateTime
            };
            console.log(requestBody);
            const data = await ApiServiceClass.CreatePushNotification(requestBody);
            console.log(data);
            if (data.status >= 200 && data.status < 300) {
                toast.success("Push notification scheduled!");
            }
            else {
                console.error("Error scheduling push notification");
                toast.error("Error scheduling push notification");
            }
        } catch (error) {
            // Handle error
            console.error("Error scheduling push notification");
            toast.error("Error scheduling push notification");
        }
        fetchData();
    };

    const getCreatePushNotifCost = async () => {
        try {
            // Prepare the request body, assuming companyId and groupId are stable values
            const requestBody = {
                CompanyId: companyId,
                CustomerGroup: groupId,
            };
            console.log(requestBody);

            // Await the API call and store the result
            const data = await ApiServiceClass.GetCreatePushNotifCost(requestBody);
            setPushCostData(data)
            console.log(data);
        } catch (error) {
            console.error("Error fetching push cost:", error);
        }
    };

    const handleInputChange = (field, value) => {
        // Cap the title at 40 characters and show warning only once
        // if (field === 'title') {
        //     if (value.length > 40 && !titleWarningShown) {
        //         setTitleWarningShown(true);
        //         toast.warning("Title cannot exceed 40 characters.");
        //     } else if (value.length <= 40) {
        //         setTitleWarningShown(false); // Reset warning when content is valid
        //     }
        //     value = value.slice(0, 40); // Always cap the title at 40 characters
        // }

        // Cap the message at 100 characters and show warning only once
        if (field === 'message') {
            if (value.length > 100 && !messageWarningShown) {
                setMessageWarningShown(true);
                toast.warning("Message cannot exceed 100 characters.");
            } else if (value.length <= 100) {
                setMessageWarningShown(false); // Reset warning when content is valid
            }
            value = value.slice(0, 100); // Always cap the message at 100 characters
        }

        setNotificationData((prev) => ({
            ...prev,
            [field]: value,
        }));

        switch (field) {
            case 'title':
                setTitle?.(value);
                break;
            case 'message':
                setMessage?.(value);
                break;
            case 'groupId':
                setGroupId?.(value);
                break;
            case 'scheduledDate':
                setScheduledDate?.(value);
                break;
            case 'scheduledTime':
                setScheduledTime?.(value);
                break;
            case 'frequencyId':
                setFrequencyId?.(value);
                break;
            case 'endDate':
                setEndDate?.(value);
                break;
            case 'endTime':
                setEndTime?.(value);
                break;
            case 'isScheduledNow':
                setIsScheduledNow?.(value);
                break;
            case 'isSeries':
                setIsSeries?.(value);
                break;
            default:
                break;
        }
    };

    const handleReviewNotification = async () => {
        const { title, message, scheduledDate, scheduledTime, isScheduledNow } = notificationData;

        // Validation checks
        // if (!title) {
        //     toast.error("Please add a title.");
        //     return;
        // }

        if (!message) {
            toast.error("Please add a message.");
            return;
        }

        if (!scheduledTime && !isScheduledNow) {
            toast.error("Please select a valid time.");
            return;
        }

        // Check if the scheduled date and time are in the future
        if (scheduledDate && scheduledTime) {
            const scheduledDateTime = new Date(`${scheduledDate}T${scheduledTime}`); // Create a Date object from the scheduled date and time
            const now = new Date(); // Get the current date and time

            if (scheduledDateTime <= now) {
                toast.error("The scheduled date and time must be in the future.");
                return;
            }
        }

        await getCreatePushNotifCost();
        console.log(pushCostData)

        // Open the review popup
        setReviewOpen(true);
    };

    const handleConfirmSchedule = () => {
        // Make API call to schedule notification
        schedulePushNotification(notificationData)

        // Reset form after submission
        setNotificationData({
            // title: "",
            message: "",
            groupId: 1,
            frequencyId: 1,
            scheduledDate: "",
            scheduledTime: "",
            endDate: "",
            endTime: "",
            isScheduledNow: false,
            isSeries: false,
        });

        handleAddClose();
        setReviewOpen(false); // Close the review popup
    };

    const {
        groupId,
        frequencyId,
        scheduledDate,
        scheduledTime,
        endDate,
        endTime,
        isScheduledNow,
        isSeries,
        title,
        message,
    } = notificationData;

    return (
        <>
            <Dialog
                open={addOpen}
                onClose={handleAddClose}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "70%",
                            maxWidth: "1200px",
                            height: "64%",
                            display: 'flex',
                            flexDirection: 'row',
                        },
                    },
                }}
            >
                <div style={{ flex: 1 }}>
                    <DialogTitle style={{ width: '100%' }}>
                        New Push Notification
                        <CloseIcon
                            onClick={() => handleAddClose()}
                            style={{ cursor: 'pointer', position: 'absolute', top: '8px', right: '8px', zIndex: 1000 }} // Updated zIndex
                        />
                    </DialogTitle>
                    <DialogContent style={{ paddingBottom: '15px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins-Medium, sans-serif', gap: '16px', margin: '10px 0' }}>
                            <div style={{ fontFamily: 'Poppins-Medium, sans-serif' }}>
                                Target Group:
                            </div>
                            <Select
                                value={groupId}
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    handleInputChange('groupId', selectedValue);
                                    setGroupId(selectedValue); // Call setGroupId here
                                }}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select Target Group' }}
                                style={{ minWidth: '150px', textAlign: 'left', fontFamily: 'Poppins-Medium, sans-serif' }}
                            >
                                <MenuItem value={1} style={{ fontFamily: 'Poppins-Medium, sans-serif' }}>All</MenuItem>
                                <MenuItem value={2} style={{ fontFamily: 'Poppins-Medium, sans-serif' }}>Active</MenuItem>
                                <MenuItem value={3} style={{ fontFamily: 'Poppins-Medium, sans-serif' }}>Inactive</MenuItem>
                                <MenuItem value={4} style={{ fontFamily: 'Poppins-Medium, sans-serif' }}>Recently Claimed</MenuItem>
                            </Select>
                            <div style={{ fontFamily: 'Poppins-Medium, sans-serif' }}>{targetGroupText}</div>
                        </div>


                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', margin: '10px 0' }}>
                            <div style={{ width: '140px', fontFamily: 'Poppins-Medium, sans-serif' }}>
                                Scheduled Time:
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '600px' }}>
                                <TextField
                                    type="date"
                                    value={scheduledDate}
                                    onChange={(e) => handleInputChange('scheduledDate', e.target.value)}
                                    disabled={isScheduledNow}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                    style={{ marginRight: '10px', width: '150px' }}
                                />
                                <TextField
                                    type="time"
                                    value={scheduledTime}
                                    onChange={(e) => handleInputChange('scheduledTime', e.target.value)}
                                    disabled={isScheduledNow}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                    style={{ marginRight: '10px', width: '100px' }}
                                />
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isScheduledNow}
                                                onChange={(e) => handleInputChange('isScheduledNow', e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Now"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* <TextField
                            label="Title"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={title}
                            onChange={(e) => handleInputChange('title', e.target.value)}
                            style={{ maxWidth: '800px' }} // Increase max width for the title field
                            helperText={`${title.length}/40`}  // Show character count for title
                            inputProps={{
                                maxLength: 100,
                                style: { fontFamily: 'Poppins-Medium, sans-serif' },
                            }}
                        /> */}

                        <TextField
                            label="Message"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={message}
                            onChange={(e) => handleInputChange('message', e.target.value)}
                            multiline
                            rows={4}
                            style={{ maxWidth: '800px' }} // Increase max width for the message field
                            helperText={`${message.length}/100`}  // Show character count for message
                            inputProps={{
                                maxLength: 100,
                                style: { fontFamily: 'Poppins-Medium, sans-serif' },
                            }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleReviewNotification}
                            style={{ marginTop: '16px' }}
                        >
                            Review
                        </Button>
                    </DialogContent>

                </div>

                {/* Image Section */}
                <div style={{ flex: 1, position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
                    <img
                        src={iphoneImage}
                        alt="Notification"
                        style={{
                            maxWidth: '90%', // Ensures image doesn't exceed the width of the container
                            maxHeight: '90%', // Ensures image doesn't exceed the height of the container
                            objectFit: 'contain', // Ensures the image is scaled properly
                            borderRadius: '8px',
                        }}
                    />

                    {/* Notification Preview Overlay */}
                    <div style={{
                        position: 'absolute',
                        top: '30%', // Adjust the vertical position as needed
                        left: '80px', // Move overlay to the left side
                        width: '230px', // Set a consistent width for the notification
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        padding: '10px',
                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                        zIndex: 10,
                        textAlign: 'left', // Align text to the left
                        overflow: 'hidden', // Prevent overflow
                        display: 'flex', // Use flexbox for alignment
                        alignItems: 'flex-start', // Align items to the start vertically
                    }}>
                        <img
                            src={iphoneImage} // Display the logo
                            alt="Logo"
                            style={{
                                maxWidth: '40px', // Set the max width for the logo
                                maxHeight: '40px', // Set the max height for the logo
                                marginRight: '16px', // Space between logo and notification image
                                objectFit: 'contain', // Ensures the logo scales correctly
                            }}
                        />
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column', // Stack title and message vertically
                            maxWidth: 'calc(100% - 36px)', // Adjust width to account for logo and margin
                        }}>
                            <h3 style={{
                                margin: '0',
                                fontSize: '14px',
                                color: '#000',
                                wordWrap: 'break-word',
                                overflow: 'hidden', // Prevent overflow
                                textOverflow: 'ellipsis', // Show ellipsis if text overflows
                                whiteSpace: 'normal', // Allow text to wrap
                                fontFamily: 'Poppins-Medium, sans-serif',
                            }}>{title}</h3>
                            <p style={{
                                margin: '5px 0 0',
                                fontSize: '12px',
                                color: '#666',
                                wordWrap: 'break-word',
                                overflow: 'hidden', // Prevent overflow
                                textOverflow: 'ellipsis', // Show ellipsis if text overflows
                                whiteSpace: 'normal', // Allow text to wrap
                                fontFamily: 'Poppins-Medium, sans-serif',
                            }}>
                                {message}
                            </p>
                        </div>
                    </div>

                </div>

            </Dialog>

            {/* Notification Review Popup */}
            {reviewOpen && (
                <NotificationReviewPopup
                    open={reviewOpen}
                    onClose={() => setReviewOpen(false)}
                    handleConfirmSchedule={handleConfirmSchedule}
                    notificationData={notificationData}
                    pushCostData={pushCostData}
                    marketingCredits={marketingCredits}
                />
            )}
        </>
    );
}

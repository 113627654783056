import React from "react";
import { Typography } from "@mui/material";
import Base64Image from "../cards/sharedcomponents/Base64Image";

const SplashContent = ({ designManager }) => {
  return (
    <div
      style={{
        height: "580px",
        display: "flex",
        zIndex: 0,
        marginTop: "-30px",
        flexDirection: "column",
        backgroundColor: designManager.getFieldValue(
          "splashScreenBackgroundColor"
        ),
      }}
    >
      <div
        style={{
          flexDirection: "column",
          alignItems: "center", // Centers items horizontally
          justifyContent: "center", // Centers items vertically
          textAlign: "center",
        }}
      >
        <div
          style={{
            paddingTop: "230px",
          }}
        >
          <Base64Image
            image={designManager.getFieldValue("splashScreenLogo")}
          />
        </div>
      </div>
    </div>
  );
};

export default SplashContent;

import React, { useState } from "react";
import { Button } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import Styles from "../resources/styles";
import ImageBox from "./sharedcomponents/ImageBox";

function LogoContent({ designManager, onCancel, onSave }) {
  
  const handleImageChange = (newLogo, newFileName, newFileSize) => {
    console.log("Changing companyLogo to", newLogo);
    designManager.updateDesign({ ["companyLogo"]: newLogo });
    designManager.updateDesign({ ["companyLogoFileName"]: newFileName });
    designManager.updateDesign({ ["companyLogoFileSize"]: newFileSize });
  };

  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>Image</div>
        <div style={Styles.content}>
          <ImageBox
            initialImage={designManager.getCurrentDesign().companyLogo}
            fileName={designManager.getCurrentDesign().companyLogoFileName}
            fileSize={designManager.getCurrentDesign().companyLogoFileSize}
            onImageChange={(newImage, newFileName, newFileSize) =>
              handleImageChange(newImage, newFileName, newFileSize)
            }
          />
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.resetBtn} onClick={onCancel}>
          Reset
          <Tooltip
            title={
              <span style={{ display: "block", textAlign: "center" }}>
                This will reset all content on this panel to the default values
              </span>
            }
            arrow
          >
            <HelpOutlineIcon
              style={{
                marginLeft: "4px",
                fontSize: "17px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </button>
        <button style={Styles.applyBtn} onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default LogoContent;

import React from "react";
import barcode from "../../../tools/pngs/barcode.png";
import QrCodeIcon from "@mui/icons-material/CardGiftcard";

const CardContent = ({ designManager }) => {
  return (
    <div
      style={{
        height: "535px",
        display: "flex",
        top: "10px",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%", // Full width of container
          backgroundColor: "#333", // Dark background color
          padding: "10px 0", // Vertical padding for height
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            color: "#fff",
            fontSize: "12px",
          }}
        >
          My Card
        </div>
      </div>
      <div
        style={{
          width: "92%",
          height: "165px",
          backgroundColor: designManager.getFieldValue(
            "loyaltyCardBackgroundColor"
          ),
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
          marginLeft: "10px",
          boxShadow: "0 6px 10px rgba(0, 0, 0, 0.70)", // Add box shadow here
        }}
      >
        {/* New container for the icon and name */}
        <div
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            marginLeft: "-30px",
            marginTop: "-20px",
          }}
        >
          <QrCodeIcon
            style={{
              width: "40px", // Size of the icon
              height: "40px",
              color: "#000000",
            }}
          />
          <div
            style={{
              fontSize: "14px", // Adjust font size as needed
              color: designManager.getFieldValue("loyaltyCardTextColor"),
            }}
          >
            John Doe
          </div>
        </div>

        <div
          style={{
            width: "93%",
            height: "62px",
            marginTop: "3px", // Adjusted for spacing
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center vertically
            backgroundColor: "#ffffff", // White background behind barcode + number
            borderRadius: "8px",
            padding: "5px 2px", // Reduced padding to make it shorter
            zIndex: 8,
          }}
        >
          <img
            src={barcode}
            alt="Barcode"
            style={{
              width: "95%",
              height: "auto",
              objectFit: "cover",
            }}
          />

          <div
            style={{
              fontSize: "14px",
              color: designManager.getFieldValue("loyaltyCardTextColor"),
              textAlign: "center",
              marginTop: "10px", // Adjusted for spacing
            }}
          >
            80837594791
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: "20px",
          fontSize: "14px",
          color: "#000000",
          textAlign: "center",
        }}
      >
        How to use my card
      </div>
    </div>
  );
};

export default CardContent;

import React, { useState } from "react";
import Styles from "../resources/styles";
import { ReactComponent as PenIcon } from "../resources/pen.svg";
import ColourBox from "./sharedcomponents/ColourBox";
import ImageBox from "./sharedcomponents/ImageBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import { ApiServiceClass } from "../../api-service";
import { toast } from "react-toastify";

function SplashScreenContent({ designManager }) {
  const [splashContent, setSplashContent] = useState({
    splashScreenBackgroundColor:
      designManager.getCurrentDesign().splashScreenBackgroundColor,
    splashScreenLogo: designManager.getCurrentDesign().splashScreenLogo,
  });

  const handleColorChange = (contentProperty, newProperty) => {
    console.log(`[UNSAVED] Changed '${contentProperty}' to:`, newProperty);
    designManager.setUnsavedChanges({ [contentProperty]: newProperty });

    setSplashContent((prevContent) => ({
      ...prevContent,
      [contentProperty]: newProperty, // Dynamically update the property
    }));
  };

  const handleImageChange = (newLogo, newFileName, newFileSize) => {
    console.log(`[UNSAVED] Changed splashScreenLogo to:`, newLogo);
    designManager.setUnsavedChanges({ ["splashScreenLogo"]: newLogo });
    designManager.setUnsavedChanges({
      ["splashScreenLogoFileName"]: newFileName,
    });
    designManager.setUnsavedChanges({
      ["splashScreenLogoFileSize"]: newFileSize,
    });
    setSplashContent((prevContent) => ({
      ...prevContent,
      splashScreenLogo: newLogo, // Update the splash screen logo
    }));
  };

  const handleSave = () => {
    if (designManager.hasUnsavedChanges()) {
      const updatedDesign = designManager.getUnsavedDesign();
      const requestBody = {
        CompanyId: designManager.getCompanyId(),
        RequesterUserId: `${designManager.getUserId()}`, // User ID in quotes
        BackgroundColor: updatedDesign.splashScreenBackgroundColor,
        SplashLogo: updatedDesign.splashScreenLogo,
      };
      console.log("Calling Splash Screen API:", requestBody);
      const response = ApiServiceClass.SaveSplashTheme(requestBody)
        .then(() => {
          designManager.updateDesign(updatedDesign);
          toast.success("Splash Screen Saved", {
            autoClose: 1200,
          });
        })
        .catch((error) => {
          console.error("Error Splash Screen Menu: ", error);
          toast.error("Error Splash Screen Menu", {
            autoClose: 1500,
          });
        });
    } else {
      console.log("No changes to save.");
    }
  };

  const handleReset = () => {
    console.log("Resetting design");
    designManager.resetUnsavedChanges();

    setSplashContent({
      splashScreenBackgroundColor:
        designManager.getCurrentDesign().splashScreenBackgroundColor,
      splashScreenLogo: designManager.getCurrentDesign().splashScreenLogo,
    });
  };

  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>Image</div>
        <div style={Styles.content}>
          <ImageBox
            initialImage={designManager.getCurrentDesign().splashScreenLogo}
            fileName={
              designManager.getCurrentDesign()?.splashScreenLogoFileName || null
            }
            fileSize={
              designManager.getCurrentDesign()?.splashScreenLogoFileSize || null
            }
            onImageChange={(newImage, newFileName, newFileSize) =>
              handleImageChange(newImage, newFileName, newFileSize)
            }
          />
        </div>
        <div style={Styles.sectionTitle}>Background</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Colour
            <ColourBox
              initColor={splashContent.splashScreenBackgroundColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("splashScreenBackgroundColor", newColor)
              }
            />
          </div>
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.resetBtn} onClick={handleReset}>
          Reset
          <Tooltip
            title={
              <span style={{ display: "block", textAlign: "center" }}>
                This will reset all content to the last saved values
              </span>
            }
            arrow
          >
            <HelpOutlineIcon
              style={{
                marginLeft: "4px",
                fontSize: "17px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </button>
        <button style={Styles.applyBtn} onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default SplashScreenContent;

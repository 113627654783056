import React from "react";
import { Typography } from "@mui/material";

const OffersContent = () => {
  return (
    <div>
      <Typography variant="h6" style={{ color: "#000" }}>
        Check out the latest offers!
      </Typography>
    </div>
  );
};

export default OffersContent;

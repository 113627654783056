import QuestionMarkIcon from "@heroicons/react/24/solid/QuestionMarkCircleIcon"; // Import the question mark icon
import React from "react";
import {
    Tooltip,
} from "@mui/material";


const MyTooltip = ({ title, customStyles = {} }) => {
    return (
      <Tooltip title={title}
      sx={{ ...customStyles, 
        minWidth: '600px',
        maxWidth: "1200px", // Set max width (adjust as needed)
        width: "auto",  // You can set width to a fixed value if needed
    }}
      >
        <QuestionMarkIcon
          style={{
            margin: "8px", // Default margin
            cursor: "help",
            width: 20, // Default width
            height: 20, // Default height
            color: "#c6c8cc", // Default color (grey)
            display: "inline-block",
          }}
        />
      </Tooltip>
    );
  };

export default MyTooltip;

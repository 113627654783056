import React from "react";
import Box from "@mui/material/Box";
import Scan from "../../tools/svgs/barcode.svg";
import Brush from "../../tools/svgs/brush.svg";
import Rocket from "../../tools/svgs/rocket.svg";
import Reward from "../../tools/svgs/gift.svg";
import Map from "../../tools/svgs/map.svg";
import Icon from "../../tools/svgs/logo.svg";
import Button from "../../tools/svgs/button.svg";
import Lock from "../../tools/svgs/lock.svg";
import Refresh from "../../tools/svgs/refresh.svg";
import Fonts from "../../tools/svgs/fonts.svg";
import Layers from "../../tools/svgs/layers.svg";

function SidePanelCard({
  icon,
  title,
  onClick,
  isLocked,
  iconWidth,
  iconHeight,
}) {
  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "16px",
    backgroundColor: "#fff",
    border: "1px solid #e0e0e0",
    borderRadius: "5px",
    cursor: isLocked ? "default" : "pointer",
    transition: "background-color 0.3s",
    width: "100%",
    position: "relative",
  };

  const titleStyle = {
    fontSize: "15px",
    marginTop: "2px",
    color: "#000000",
    opacity: isLocked ? 0.5 : 1,
  };

  const getIconSrc = () => {
    switch (icon) {
      case "themes":
        return Layers;
      case "scanner":
        return Scan;
      case "gift":
        return Reward;
      case "paint":
        return Brush;
      case "fonts":
        return Fonts;
      case "icon":
        return Icon;
      case "launch":
        return Rocket;
      case "navigation":
        return Map;
      case "button":
        return Button;
      case "reset":
        return Refresh;
      default:
        return null;
    }
  };

  return (
    <div style={cardStyle} onClick={!isLocked ? onClick : undefined}>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={getIconSrc()}
          sx={{
            width: iconWidth,
            height: iconHeight,
            opacity: isLocked ? 0.5 : 1,
          }}
        />
        {isLocked && (
          <Box
            component="img"
            src={Lock}
            sx={{
              position: "absolute",
              top: 5,
              left: -105,
              width: "25px",
              height: "25px",
              transform: "translate(80%, -50%)", // Moved further to the right
            }}
          />
        )}
      </Box>
      <div style={titleStyle}>{title}</div>
    </div>
  );
}

export default SidePanelCard;

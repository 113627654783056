import React, { useState } from "react";
import {
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import Styles from "../../resources/styles";

function NumberBox({ defaultValue = 8, maxValue = 16, onCancel, onSave }) {
  const [numberValue, setNumberValue] = useState(defaultValue);

  const handleChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value !== "") {
      const numericValue = Number(value);
      if (numericValue <= maxValue) {
        setNumberValue(numericValue); // Set value if it's within range
      } else {
        setNumberValue(maxValue); // Set to max value if exceeded
      }
    }
  };

  const handleIncrement = () => {
    setNumberValue((prevValue) => Math.min(prevValue + 1, maxValue)); // Prevent exceeding maxValue
  };

  const handleDecrement = () => {
    setNumberValue((prevValue) => Math.max(prevValue - 1, 0)); // Prevent negative values
  };

  return (
    <FormControl>
      <div style={Styles.numberBox}>
        <TextField
          id="number-input"
          value={numberValue}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  onClick={handleDecrement}
                  style={{ padding: 0, marginRight: "8px" }} // Add margin to align properly
                >
                  <Remove />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleIncrement}
                  style={{ padding: 0, marginLeft: "8px" }} // Add margin to align properly
                >
                  <Add />
                </IconButton>
              </InputAdornment>
            ),
            style: { fontSize: "16px", textAlign: "center" }, // Center the text in the input
          }}
          variant="outlined" // To ensure borders are properly aligned
          inputProps={{
            style: {
              textAlign: "center", // Center the text inside the input
              // Hide the up/down arrows in the number input
            },
          }}
        />
      </div>
    </FormControl>
  );
}

export default NumberBox;

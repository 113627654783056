import React from "react";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import FontBox from "./sharedcomponents/FontBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

function ButtonsContent({ fonts, designManager, onCancel, onSave }) {
  const handleColorChange = (colorProperty, newColor) => {
    console.log(`Changing '${colorProperty}' to`, newColor);
    designManager.updateDesign({ [colorProperty]: newColor });
  };

  const handleFontChange = (fontProperty, newFont) => {
    console.log(`Changing '${fontProperty}' to`, newFont);
    designManager.updateDesign({ [fontProperty]: newFont });
  };

  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>Colours</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox
              initialColor={
                designManager.getCurrentDesign().buttonBackgroundColor
              }
              onColorChange={(newColor) =>
                handleColorChange("buttonBackgroundColor", newColor)
              }
            />
          </div>
          <div style={Styles.gridItemRight}>
            Font Colour
            <ColourBox
              initialColor={designManager.getCurrentDesign().buttonFontColor}
              onColorChange={(newColor) =>
                handleColorChange("buttonFontColor", newColor)
              }
            />
          </div>
        </div>
        <div style={Styles.sectionTitle}>Fonts</div>
        <div style={Styles.content}>
          Font Style
          <FontBox
            fonts={fonts}
            initialFont={designManager.getCurrentDesign().buttonFont}
            onFontChange={(newFont) => handleFontChange("buttonFont", newFont)}
          />
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.resetBtn} onClick={onCancel}>
          Reset
          {/* Tooltip with smaller icon, positioned to the right */}
          <Tooltip
            title={
              <span style={{ display: "block", textAlign: "center" }}>
                This will reset all content on this panel to the default values
              </span>
            }
            arrow
          >
            <HelpOutlineIcon
              style={{
                marginLeft: "4px", // Space between "Reset" and the icon
                fontSize: "17px", // Smaller icon size
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </button>
        <button style={Styles.applyBtn} onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default ButtonsContent;

import React from "react";
import { Typography } from "@mui/material";

const HomeContent = () => {
  return (
    <div>
      <Typography variant="h6" style={{ color: "#000" }}>
        Welcome to the Home tab!
      </Typography>
    </div>
  );
};

export default HomeContent;

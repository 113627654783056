import React, { useState, useEffect } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

const FontBox = ({ initialFont, fonts = [], designManager, onFontChange }) => {
  // Set the initial font if provided or fallback to "Arial"
  const [selectedFont, setSelectedFont] = useState(initialFont || "Arial");

  // Handle font selection change
  const handleChangeComplete = (event) => {
    const newFont = event.target.value; // Get the font selected by the user
    setSelectedFont(newFont); // Update local state with the selected font
    onFontChange(newFont); // Call the prop function to update the parent or designManager
  };

  return (
    <div>
      <FormControl
        style={{ width: "40%", marginBottom: "10px", marginTop: "5px" }}
      >
        <Select
          id="font-select"
          value={selectedFont} // selectedFont is now a string
          style={{ backgroundColor: "#fff" }}
          onChange={handleChangeComplete} // Event handler for font change
        >
          {fonts.map((font) => (
            <MenuItem
              key={font.FontTechnicalName}
              value={font.FontTechnicalName} // Use FontTechnicalName as the value
              style={{ fontFamily: font.FontTechnicalName }} // Display font using the font family
            >
              {font.FontFriendlyName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default FontBox;

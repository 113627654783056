import React, { useState, useEffect } from "react";
import { Image } from "lucide-react";

function BrandLogoUpload({ initialImage, fileName, fileSize, onImageChange }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [fileInfo, setFileInfo] = useState({ name: "", size: 0 });

  useEffect(() => {
    if (initialImage) {
      const imageUrl = `data:image/png;base64,${initialImage}`;
      setPreviewUrl(imageUrl);
      setFileInfo({
        name: fileName || "Uploaded Logo",
        size: fileSize ? (fileSize / 1024).toFixed(2) : 0,
      });
    }
  }, [initialImage, fileName, fileSize]);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 5 * 1024 * 1024) {
      const reader = new FileReader();
      setSelectedFile(file);

      reader.onloadend = () => {
        const base64 = reader.result.split(",")[1];
        setPreviewUrl(reader.result);
        onImageChange(base64, file.name, file.size);
        setFileInfo({ name: file.name, size: (file.size / 1024).toFixed(2) });
      };

      reader.readAsDataURL(file);
    } else {
      alert("File size must be less than 5MB");
    }
  };

  const styles = getStyles(!!selectedFile || !!initialImage);

  return (
    <div style={styles.container}>
      <div style={styles.uploadBox}>
        <div style={previewUrl ? styles.imageContainer : styles.iconContainer}>
          {previewUrl ? (
            <img
              src={previewUrl}
              alt="Uploaded logo"
              style={styles.previewImage}
            />
          ) : (
            <Image size={48} style={styles.icon} />
          )}
        </div>

        {fileInfo.name && fileInfo.size ? (
          <div style={styles.fileInfo}>
            Uploaded: {fileInfo.name} ({fileInfo.size} KB)
          </div>
        ) : (
          <div style={styles.textContainer}>
            <p style={styles.mainText}>
              Upload company logo (.jpg .jpeg .png .svg)
            </p>
            <p style={styles.subText}>Max file size 5MB</p>
          </div>
        )}

        <label style={styles.inputLabel}>
          <input
            type="file"
            style={styles.hiddenInput}
            accept=".jpg,.jpeg,.png,.svg"
            onChange={handleFileSelect}
          />
          <div style={styles.uploadButton}>
            {selectedFile || initialImage ? "Change Logo" : "Upload Logo"}
          </div>
        </label>
      </div>
    </div>
  );
}

const getStyles = (hasFile) => ({
  container: {
    width: "100%",
    maxWidth: "500px",
    margin: "0 auto",
  },
  uploadBox: {
    borderRadius: "8px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
  },
  iconContainer: {
    width: "100px",
    height: "100px",
    backgroundColor: "#f3f4f6",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  imageContainer: {
    width: "300px",
    height: "300px",
    backgroundColor: "#f3f4f6",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  icon: {
    color: "#9ca3af",
  },
  previewImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  textContainer: {
    textAlign: "center",
  },
  mainText: {
    color: "#4b5563",
    marginBottom: "4px",
  },
  subText: {
    color: "#6b7280",
    fontSize: "14px",
  },
  inputLabel: {
    width: "100%",
    cursor: "pointer",
  },
  hiddenInput: {
    display: "none",
  },
  uploadButton: {
    display: "flex",
    margin: "0 auto",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    padding: "10px 12px",
    backgroundColor: "#3464eb",
    color: "white",
    textAlign: "center",
    borderRadius: "6px",
    cursor: "pointer",
    transition: "background-color 0.2s ease",
  },
  fileInfo: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontSize: "14px",
    color: "#4b5563",
    textAlign: "center",
  },
});

export default BrandLogoUpload;

import React, { useState } from "react";
import { Button } from "@mui/material";
import { SketchPicker } from "react-color";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import ImageBox from "./sharedcomponents/ImageBox";
import ThemeSelector from "../cards/ThemeSelector";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

function ThemesContent({ onCancel, onSave }) {
  const [color, setColor] = useState("#fff"); // Default color
  const [selectedTheme, setselectedTheme] = useState(0);

  const handleChangeComplete = (color) => {
    setColor(color.hex);
  };

  return (
    <>
      <div>
        <div style={Styles.panel}>
          <div style={Styles.contentWrapper}>
            <div style={Styles.content}>
              <ThemeSelector></ThemeSelector>
            </div>
          </div>
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.resetBtn} onClick={onCancel}>
          Reset
          {/* Tooltip with smaller icon, positioned to the right */}
          <Tooltip
            title={
              <span style={{ display: "block", textAlign: "center" }}>
                This will reset all content on this panel to the default values
              </span>
            }
            arrow
          >
            <HelpOutlineIcon
              style={{
                marginLeft: "4px", // Space between "Reset" and the icon
                fontSize: "17px", // Smaller icon size
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </button>
        <button style={Styles.applyBtn} onClick={onSave}>
          Save
        </button>
      </div>
    </>
  );
}

export default ThemesContent;

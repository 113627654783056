import React, { useState } from "react";
import { Button } from "@mui/material";
import { SketchPicker } from "react-color";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import ImageBox from "./sharedcomponents/ImageBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

function ColorsContent({ onCancel, onSave }) {
  const [color, setColor] = useState("#fff"); // Default color

  const handleChangeComplete = (color) => {
    setColor(color.hex);
  };

  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>App Header</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox />
          </div>
          <div style={Styles.gridItemRight}>
            Font Colour
            <ColourBox />
          </div>
        </div>
        <div style={Styles.sectionTitle}>Primary Brand</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox position={"left"} />
          </div>
          <div style={Styles.gridItemRight}>
            Font Colour
            <ColourBox />
          </div>
        </div>

        <div style={Styles.sectionTitle}>Navigation Menu</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox />
          </div>
          <div style={Styles.gridItemRight}>
            Selected Colour
            <ColourBox />
          </div>
          <div style={Styles.gridItemLeft}>
            Deselected Colour
            <ColourBox />
          </div>
        </div>

        <div style={Styles.sectionTitle}>Splash Screen</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox />
          </div>
        </div>

        <div style={Styles.sectionTitle}>Buttons</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox />
          </div>
          <div style={Styles.gridItemRight}>
            Font Colour
            <ColourBox />
          </div>
        </div>

        <div style={Styles.sectionTitle}>Digital Loyalty Card</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox />
          </div>
          <div style={Styles.gridItemRight}>
            Text Colour
            <ColourBox />
          </div>
        </div>
        <div style={Styles.sectionTitle}>Reward Scheme</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Text Colour
            <ColourBox />
          </div>
          <div style={Styles.gridItemRight}>
            Stamp Colour
            <ColourBox />
          </div>
        </div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Stamp Border Colour
            <ColourBox />
          </div>
          <div style={Styles.gridItemRight}>
            Panel Background Colour
            <ColourBox />
          </div>
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.resetBtn} onClick={onCancel}>
          Reset
          {/* Tooltip with smaller icon, positioned to the right */}
          <Tooltip
            title={
              <span style={{ display: "block", textAlign: "center" }}>
                This will reset all content on this panel to the default values
              </span>
            }
            arrow
          >
            <HelpOutlineIcon
              style={{
                marginLeft: "4px", // Space between "Reset" and the icon
                fontSize: "17px", // Smaller icon size
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </button>
        <button style={Styles.applyBtn} onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default ColorsContent;

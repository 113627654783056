import MyTooltip from "./my-tooltip";
import React, { useEffect, useRef } from "react";


export const MyTextField = ({ name, tooltip, placeholder, text, setText, id }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
          <div>{name}</div>
          <MyTooltip title={tooltip} />
        </div>
        <div style={{
          position: "relative",
          border: "1px solid black",
          borderRadius: "5px",
          height: '50px',
          width: '230px',
          padding: "10px",
          // minWidth: '170px',
          // maxWidth: '250px',
          textAlign: 'left'
        }}>
          <input
            // type="text"
            // type="number"
            variant="outlined"
            placeholder={placeholder}
            // fullWidth
            id={id}
            value={text}
            onChange={(event) => setText(event.target.value)}
            style={{
              border: "none",
              outline: "none",
              width: "100%",
              fontSize: "inherit",
              fontFamily: "inherit",
              backgroundColor: "transparent",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const MyNumberField = ({ name, tooltip, placeholder, value, setValue, id, minValue=1, maxValue=12 }) => {
  const setPurchasesWithLimits = (val) => {
    let newValue = parseInt(val, 10);
    newValue = isNaN(newValue) ? 1 : Math.min(Math.max(newValue, minValue), maxValue);
    setValue(newValue);
  };

  const handleIncrement = () => {
    setPurchasesWithLimits(value + 1);
  };

  const handleDecrement = () => {
    setPurchasesWithLimits(value - 1);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <style>
        {`
            /* Hide the spinner arrows in WebKit browsers (Chrome, Safari) */
            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
  
            /* Hide the spinner arrows in Firefox */
            input[type=number] {
              -moz-appearance: textfield;
            }
          `}
      </style>
      <div>
        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
          {name && <div>{name}</div>}
          {tooltip && <MyTooltip title={tooltip} />}
        </div>
        <div style={{
          position: "relative",
          border: "1px solid black",
          borderRadius: "5px",
          height: '50px',
          width: '230px',
          padding: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}>
          <button onClick={handleDecrement} style={{
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            cursor: "pointer",
            fontSize: "20px",
            fontFamily: "inherit",
            padding: "0 10px"
          }}>-</button>
          <input
            type="number"
            variant="outlined"
            placeholder={placeholder}
            id={id}
            value={value}
            onChange={(event) => {
              const val = event.target.value;
              if (val === '' || val === '-') {
                setValue(1); // set to minimum allowed value
              } else {
                setPurchasesWithLimits(val);
              }
            }}
            style={{
              border: "none",
              outline: "none",
              width: "50px",
              fontSize: "inherit",
              fontFamily: "inherit",
              backgroundColor: "transparent",
              textAlign: 'center',
              MozAppearance: "textfield", // Hide spinners in Firefox
              WebkitAppearance: "none" // Hide spinners in WebKit browsers
            }}
            min="1"
          />
          <button onClick={handleIncrement} style={{
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            cursor: "pointer",
            fontSize: "20px",
            fontFamily: "inherit",
            padding: "0 10px"
          }}>+</button>
        </div>
      </div>
    </div>
  );
};
import React from "react";

const Base64Image = ({ image, altText = "Image", style = {} }) => {
  // Check if the base64 string is valid
  if (!image) {
    return <p>No image provided.</p>;
  }

  // Determine the image type based on the base64 content, and add prefix if missing
  const isPrefixed = image.startsWith("data:image");
  let src;

  if (isPrefixed) {
    // If image has prefix, use it directly
    src = image;
  } else {
    // If image doesn't have prefix, assume JPEG or adjust based on file type as needed
    src = `data:image/png;base64,${image}`;
  }

  return (
    <img
      src={src}
      alt={altText}
      style={{ maxWidth: "50%", maxHeight: "50%", ...style }}
    />
  );
};

export default Base64Image;

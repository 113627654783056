import React from "react";
import { Typography, Button } from "@mui/material";
import Base64Image from "../cards/sharedcomponents/Base64Image";

const LoginContent = ({ designManager }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          width: "100%", // Full width of container
          backgroundColor: "#333", // Dark background color
          padding: "10px 0", // Vertical padding for height
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            color: "#fff",
            fontSize: "12px",
          }}
        >
          Login
        </div>
      </div>

      {/* Company Logo */}
      <div
        style={{
          marginTop: "10%",
        }}
      >
        <Base64Image
          image={designManager.getCurrentDesign().companyLogo}
          style={{ width: "40%", height: "40%" }}
        />
      </div>

      {/* Email Label */}
      <div
        style={{
          fontSize: "10px",
          color: "#000000",
          alignSelf: "flex-start",
          paddingLeft: "40px",
          marginTop: "15%",
        }}
      >
        Email Address
      </div>

      {/* Email Input */}
      <input
        type="text"
        placeholder=""
        style={{
          width: "210px",
          height: "35px",
          padding: "10px",
          fontSize: "16px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      />

      {/* Password Label */}
      <div
        style={{
          fontSize: "10px",
          color: "#000000",
          alignSelf: "flex-start",
          paddingLeft: "40px",
          marginTop: "5%",
        }}
      >
        Password
      </div>

      {/* Password Input */}
      <input
        type="password"
        placeholder=""
        style={{
          width: "210px",
          height: "35px",
          padding: "10px",
          fontSize: "16px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      />

      {/* Sign In Button */}
      <Button
        style={{
          height: "30px",
          backgroundColor: "#000000",
          color: "#ffffff",
          width: "210px",
          borderRadius: "15px",
          marginTop: "20px",
          fontSize: "12px",
        }}
      >
        Sign In
      </Button>

      {/* Register Link */}
      <div
        style={{
          fontSize: "10px",
          color: "#000000",
          textDecoration: "underline",
          marginTop: "15px",
        }}
      >
        Don't have an Account? Register Here!
      </div>
    </div>
  );
};

export default LoginContent;

import React, { useEffect, useState } from "react";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import ImageBox from "./sharedcomponents/ImageBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import { ApiServiceClass } from "../../../components/api-service";
import { toast } from "react-toastify";

function LoyaltyContent({ designManager }) {
  const [loyaltyContent, setLoyaltyContent] = useState({
    loyaltyCardTextColor: designManager.getCurrentDesign().loyaltyCardTextColor,
    loyaltyCardBackgroundColor:
      designManager.getCurrentDesign().loyaltyCardBackgroundColor,
  });

  const handleColorChange = (contentProperty, newProperty) => {
    console.log(`[UNSAVED] Changed '${contentProperty}' to:`, newProperty);
    designManager.setUnsavedChanges({ [contentProperty]: newProperty });

    setLoyaltyContent((prevContent) => ({
      ...prevContent,
      [contentProperty]: newProperty, // Dynamically update the property
    }));
  };

  const handleSave = () => {
    if (designManager.hasUnsavedChanges()) {
      const updatedDesign = designManager.getUnsavedDesign();
      const requestBody = {
        CompanyId: designManager.getCompanyId(),
        RequesterUserId: `${designManager.getUserId()}`, // User ID in quotes
        BackgroundColor: updatedDesign.loyaltyCardBackgroundColor,
        TextColor: updatedDesign.loyaltyCardTextColor,
      };
      console.log("Calling SaveLoyaltyCardTheme API:", requestBody);
      const response = ApiServiceClass.SaveLoyaltyCardTheme(requestBody)
        .then(() => {
          designManager.updateDesign(updatedDesign);
          toast.success("Loyalty Card Saved", {
            autoClose: 1200,
          });
        })
        .catch((error) => {
          console.error("Error Loyalty Card Menu: ", error);
          toast.error("Error Loyalty Card Menu", {
            autoClose: 1500,
          });
        });
    } else {
      console.log("No changes to save.");
    }
  };

  const handleReset = () => {
    console.log("Resetting design");
    designManager.resetUnsavedChanges();

    setLoyaltyContent({
      loyaltyCardTextColor:
        designManager.getCurrentDesign().loyaltyCardTextColor,
      loyaltyCardBackgroundColor:
        designManager.getCurrentDesign().loyaltyCardBackgroundColor,
    });
  };

  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>Colours</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox
              initColor={loyaltyContent.loyaltyCardBackgroundColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("loyaltyCardBackgroundColor", newColor)
              }
            />
          </div>
          <div style={Styles.gridItemRight}>
            Text Colour
            <ColourBox
              initColor={loyaltyContent.loyaltyCardTextColor}
              onColorChange={(newColor) =>
                handleColorChange("loyaltyCardTextColor", newColor)
              }
            />
          </div>
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.resetBtn} onClick={handleReset}>
          Reset
          <Tooltip
            title={
              <span style={{ display: "block", textAlign: "center" }}>
                This will reset all content to the last saved values
              </span>
            }
            arrow
          >
            <HelpOutlineIcon
              style={{
                marginLeft: "4px",
                fontSize: "17px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </button>
        <button style={Styles.applyBtn} onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default LoyaltyContent;
